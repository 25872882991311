.voucher-page-holder .voucher-item-holder {
    display: flex;
    gap: 50px;
    margin-top: 40px;
    cursor: pointer;
    padding-left: 46px;
}

.voucher-page-holder .voucher-item-holder img {
    max-width: 100%;
}

.voucher-page-holder .voucher-item-holder .title {
    font-size: 18px;
    font-family: parka-bold;
    line-height: 22px;
    margin-top: 11px;
    color: rgba(0, 0, 0, 0.65);
}

.voucher-page-holder .voucher-item-holder .description {
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.65);
}

@media screen and (max-width: 767.98px) {
    .voucher-page-holder .voucher-item-holder {
        flex-direction: column;
        gap: 10px;
        text-align: center;
        max-width: 350px;
        margin: auto;
        margin-top: 40px;
        padding-left: 15px;
    }
}