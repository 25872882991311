.document-form-holder {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  background-color: #fff;
  padding: 30px 15px;
  padding-bottom: 45px;
  overflow-y: scroll;
}

.document-form-holder .close-form {
  display: block;
  border: none;
  background: none;
  margin-left: auto;
  font-size: 30px;
  color: #191a1c;
  margin-right: 20px;
  width: 14px;
  position: relative;
  top: -10px;
}

.document-form-holder .form-holder {
  margin: auto;
}

.document-form-holder form .group-space {
  margin: 85px 0;
}

.document-form-holder form h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.document-form-holder form p {
  font-size: 12px;
  color: #555;
  margin-top: -14px;
  margin-bottom: 23px;
}

.document-form-holder form .input-text-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 23px;
}

.document-form-holder form .input-text-inline .form-group {
  margin-bottom: 0;
  max-width: 140px;
}

.document-form-holder form .input-para {
  font-size: 16px;
  line-height: 22px;
  margin-top: 12px;
}

.document-form-holder form .new-key-select-holder {
  margin-top: 28px;
  margin-bottom: 50px;
}

.document-form-holder form .new-key-select-holder .open-options-holder {
  border: none;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #333;
  padding-bottom: 20px;
}

.document-form-holder form .new-key-select-holder .open-options-holder span {
  background-color: #26f8ab;
  color: #333;
  font-size: 24px;
  width: 25px;
  height: 25px;
  display: block;
}

.document-form-holder form .new-key-select-holder .open-options-holder:hover {
  color: #555;
}

.document-form-holder form .new-key-select-holder .options-holder {
  margin-bottom: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 8px;
}

.document-form-holder form .new-key-select-holder .options-holder li button {
  border: none;
  background: #f0f0f0;
  color: #333;
  padding: 8px 12px;
  border-radius: 5px;
}

.document-form-holder form .new-key-select-holder .options-holder li button:hover {
  background: #f8f8f8;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.document-form-holder form .selected-inputs-holder {
  margin-top: 28px;
}

.document-form-holder form .selected-inputs-holder li {
  margin-top: 18px;
}

.document-form-holder form .selected-inputs-holder li .selected-input-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
  gap: 8px;
}

.document-form-holder form .selected-inputs-holder li .selected-input-header button {
  border: none;
  background: #f0f0f0;
  color: #333;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.document-form-holder form .selected-inputs-holder li .selected-input-header button:hover {
  background: #f8f8f8;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.document-form-holder form .selected-inputs-holder li .line {
  width: 100%;
  height: 1px;
  margin: auto;
  margin-top: 21px;
  background-color: #ddd;
}

.document-form-holder form .selected-inputs-holder li:last-child .line {
  display: none;
}

.document-form-holder form .upload-preview .item {
  -ms-flex-preferred-size: 23.28%;
      flex-basis: 23.28%;
}
