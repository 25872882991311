body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'parka-bold';
  src: local('parka-bold'), url('./fonts/parka-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'parka-regular';
  src: local('parka-regular'), url('./fonts/parka-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'parka-light';
  src: local('parka-light'), url('./fonts/parka-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}