:root {
  --brand-dark-grey: #415055;
}

.mobile-redirect-popup-holder {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--brand-dark-grey);
  padding: 17px;
}

.mobile-redirect-popup-holder .close-popup-btn {
  color: #cfcfcf;
  height: 17px;
  line-height: 17px;
  width: 17px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 24px;
}

.mobile-redirect-popup-holder .mobile-redirect-popup-logo img {
  background-size: cover;
  border-radius: 10px;
  height: 45px;
  margin-left: 12px;
  width: 45px;
}

.mobile-redirect-popup-holder .mobile-redirect-popup-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2em;
  color: white;
  margin: 0;
  margin-left: auto;
}

.mobile-redirect-popup-holder .mobile-redirect-link {
  min-width: 87px;
  color: #6a6a6a;
  font-size: 15px;
  font-weight: 500;
  height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 24px;
  margin-left: auto;
  text-align: center;
  text-decoration: none;
  background-color: #26f8ab;
  border: none;
  outline: none;
}
