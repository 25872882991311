.disclaimer-text {
  margin-top: 50px;
  color: #a0a7aa;
}
.heading-address {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}
.day-slots-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.modal-body-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-p {
  line-height: 24px;
  font-size: 16px;
}
.error-message {
  font-size: 13px;
  color: #cc0000;
  margin-top: 11px;
}
.modal-body-wrapper textarea {
  height: 120px;
}