.gcp-upload-zone {
  margin: 0 auto;
  display: block;
  width: 200px;
  height: 200px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}
.gcp-upload-preview__item {
  position: relative;
  display: inline-block;
  height: 190px;
  width: 190px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 20px;
  overflow: hidden;
}
.gcp-upload-preview__item:nth-child(3n){
  margin-right: 0;
}

.gcp-upload-preview__item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gcp-upload-preview__remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #c70c0c;
  cursor: pointer;
  font-size: 24px;
}

.gcp-upload-preview__remove-icon:hover {
  opacity: 0.8;
}

