.energy-heating-holder .energy-contract-picker,
.heating-energy-modal  .energy-contract-picker {
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-bottom: 21px;
}

.heating-energy-modal  .energy-contract-picker {
   margin-top: 20px;
   margin-bottom: 21px;
}

.energy-heating-holder .energy-date-picker {
    width: 100%;
    max-width: 350px;
    position: relative;
    margin: auto;
    margin-bottom: 35px;
    margin-top: 20px;
}

.energy-heating-holder .energy-date-picker .icon-wrap {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.energy-heating-holder .energy-date-picker .react-datepicker-wrapper input {
    font-size: 16px;
    padding: 16px 20px;
    border-radius: 0 !important;
    border: 1px solid #dfdfdf !important;
    height: auto;
    width: 100%;
    outline: none !important;
}
.energy-no-data p {
    font-size: 18px;
    height: auto;
    width: 100%;
    text-align: center;
}

.energy-no-data .energy-no-data-img-holder {
    display: flex;
    justify-content: center;
    margin: 50px 0px;
}

.energy-no-data .energy-no-data-img-holder img {
    max-width: 120px;
}

.energy-heating-holder .energy-date-picker .react-datepicker-wrapper input:focus {
    border: 1px solid var(--brand-green) !important;
    box-shadow: none!important;
}

.energy-heating-holder .energy-date-picker .react-datepicker__month-container .react-datepicker__header {
    background-color: var(--brand-green);
}

.energy-heating-holder .energy-date-picker .react-datepicker__header__dropdown {
    margin-top: 10px;
}

.energy-heating-holder .energy-date-picker .react-datepicker__current-month,
.energy-heating-holder .energy-date-picker .react-datepicker__day-name {
    color: #415055;
}

.energy-heating-holder .energy-date-picker .react-datepicker__navigation-icon::before,
.energy-heating-holder .energy-date-picker .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #415055;
}

.energy-heating-holder .energy-date-picker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: var(--brand-green);
}

.energy-heating-holder .energy-date-picker .react-datepicker__month-container .react-datepicker__day--selected,
.energy-heating-holder .energy-date-picker .react-datepicker__month-container .react-datepicker__day--keyboard-selected {
    background-color: var(--brand-green);
    border-radius: 0;
}

.energy-heating-holder .energy-heating-items-holder {
    display: flex;
    padding-bottom: 100px;
}

.energy-heating-holder .energy-heating-items-holder .heating-item-holder {
    width: 50%;
    padding: 15px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .head-holder {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .head-holder h2 {
    text-align: center;
    font-weight: bold;
    color: #415055;
    margin-bottom: 0;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder.heating .head-holder h2 {
    margin-top: 2px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder.heating .head-holder img {
    max-width: 52px;
    max-height: 48px;
    margin-right: 21px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder.warm-water .head-holder img {
    max-width: 38px;
    max-height: 48px;
    margin-right: 21px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder {
    display: flex;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder .energy-info-holder {
    flex-grow: 1;
    max-width: 85%;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder .energy-info-holder .energy-item-holder {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder .energy-info-holder .energy-item-holder p {
    font-size: 18px;
    color: #415055;
    line-height: 24px;
 }

 .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder .energy-info-holder .energy-item-holder p:first-child {
    font-weight: 600;
    max-width: 70%;
 }

 .heating-energy-modal .description {
    font-size: 18px;
    max-width: 650px;
    margin: 0 auto 30px;
    line-height: 1.5;
 }

 .heating-energy-modal .btns-holder {
    display: flex;
    justify-content: center;
    margin-top: 80px;
 }

 .energy-consumption-notification-holder {
    max-width: 320px;
    background-color: #fff;
    position: absolute;
    border: 1px solid #ecedee;
    border-radius: 5px;
    padding: 8px 12px 12px 12px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
 }

 .energy-consumption-notification-holder .notification-header {
    display: flex;
    align-items: center;
 }

 .energy-consumption-notification-holder .notification-header img {
    margin-right: 5px;
    width: 22px;
    height: 25px;
 }

 .energy-consumption-notification-holder .notification-header h3 {
    font-size: 18px;
    color: #677377;
    font-weight: bold;
    margin-bottom: 0;
 }

 .energy-consumption-notification-holder .notification-header h3::after {
    content: '';
    display: block;
    position: static;
    width: 100%;
    height: 2px;
    background-color: #fab900;
 }

 .energy-consumption-notification-holder .notification-text {
    color: #677377;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
 }

 .energy-consumption-notification-holder .notification-buttons-holder {
    display: flex;
    gap: 10px;
 }

 .energy-consumption-notification-holder .notification-buttons-holder .secondary-notification-button {
    border: 1px solid #ecedee;
    padding: 8px 12px;
    color: #677377;
    outline: none;
 }

 .energy-consumption-notification-holder .notification-buttons-holder .primary-notification-button {
    border: 1px solid #fab900;
    background-color: #fab900;
    padding: 8px 12px;
    color: #fff;
    outline: none;
 }

@media screen and (max-width: 767.98px) {
    .energy-heating-holder .energy-heating-items-holder {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
        align-items: center;
    }
    .energy-heating-holder .energy-heating-items-holder .heating-item-holder {
        width: 80%;
        margin-bottom: 21px;
    }
}
 @media screen and (max-width: 575.98px) {
    .energy-heating-holder .energy-heating-items-holder .heating-item-holder {
        width: 100%;
        padding: 0px;
     }
     .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder img {
        max-width: 50px;
        max-height: 50px;
        margin-right: 18px;
     }
     .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder .energy-info-holder {
        max-width: 100%;
     }
     .energy-heating-holder .energy-heating-items-holder .heating-item-holder .item-content-holder .energy-info-holder .energy-item-holder p {
        font-size: 16px;
        line-height: 22px;
     }
 }