.document-form-holder {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    background-color: #2c2c2e;
    padding: 30px 15px;
    padding-bottom: 45px;
    overflow-y: scroll;
    .close-form {
        display: block;
        border: none;
        background: none;
        margin-left: auto;
        font-size: 30px;
        color: #1b1c1c;
        margin-right: 20px;
        width: 14px;
        position: relative;
        top: -10px;
    }
    .form-holder {
        margin: auto;
    }

    form {
        .group-space {
            margin: 85px 0;
        }
        h3 {
            font-size: 18px;
            color: #333;
            margin-bottom: 10px;
        }
        p {
            font-size: 12px;
            color: #555;
            margin-top: -14px;
            margin-bottom: 23px;
        }
        .input-text-inline {
            display: flex;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;
            margin-bottom: 23px;
            .form-group {
                margin-bottom: 0;
                max-width: 140px;
            }
        }
        .input-para {
            font-size: 16px;
            line-height: 22px;
            margin-top: 12px;
        }
        .new-key-select-holder {
            margin-top: 28px;
            margin-bottom: 50px;
            .open-options-holder {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 16px;
                color: #333;
                padding-bottom: 20px;
                span {
                    background-color: #26f8ab;
                    color: #333;
                    font-size: 24px;
                    width: 25px;
                    height: 25px;
                    display: block;
                }
                &:hover {
                    color: #555;
                }
            }
            .options-holder {
                margin-bottom: 20px;
                flex-wrap: wrap;
                gap: 8px;
                li {
                    button {
                        border: none;
                        background: #f0f0f0;
                        color: #333;
                        padding: 8px 12px;
                        border-radius: 5px;
                        &:hover {
                            background: #f8f8f8;
                            transition: 0.3s;
                        }
                    }
                }
            }
        }
        .selected-inputs-holder {
            margin-top: 28px;
            li {
                margin-top: 18px;
                .selected-input-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    gap: 8px;
                    button {
                        border: none;
                        background: #f0f0f0;
                        color: #333;
                        width: 25px;
                        height: 25px;
                        border-radius: 5px;
                        &:hover {
                            background: #f8f8f8;
                            transition: 0.3s;
                    }
                    }
                }
                .line {
                    width: 100%;
                    height: 1px;
                    margin: auto;
                    margin-top: 21px;
                    background-color: #ddd;
                }
                &:last-child {
                    .line {
                        display: none;
                    }
                }
            }
        }
        .upload-preview .item {
            flex-basis: 23.28%;
        }
    }
}