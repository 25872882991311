.mobile-redirect-popup-holder {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  .close-popup-btn {
    color: #cfcfcf;
    height: 17px;
    line-height: 17px;
    margin: 0 7px;
    width: 17px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 24px;
  }
  .mobile-redirect-popup-logo {
    img {
      background-size: cover;
      border-radius: 10px;
      height: 45px;
      margin-right: 12px;
      width: 45px;
    }
  }
  .mobile-redirect-popup-text {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.2em;
    color: #415055;
    margin-right: 12px;
  }
  .mobile-redirect-link {
    min-width: 87px;
    color: #6a6a6a;
    font-size: 15px;
    font-weight: 500;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    margin: 23px 30px 23px auto;
    text-align: center;
    text-decoration: none;
    background-color: #26f8ab;
    border: none;
    outline: none;  
  }
}