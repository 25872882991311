* {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'sans-serif';
}

@font-face {
font-family: 'parka-bold';
src: local('parka-bold'), url('../../fonts/parka-bold.woff') format('woff');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'parka-regular';
src: local('parka-regular'), url('../../fonts/parka-regular.woff') format('woff');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'parka-light';
src: local('parka-light'), url('../../fonts/parka-light.woff') format('woff');
font-weight: normal;
font-style: normal;
}

.wrap-privacy-policy {
    padding-top: 20px;
    padding-bottom: 30px;
}

.wrap-privacy-calendar {
    padding-top: 63px;
    padding-bottom: 30px;
}

.consultation-message-web:is(h1, h2, p, a, b) {
    align-self: flex-start;
    text-align: left;
    line-height: 26px;
    padding-bottom: 41px;
    font-size: 18px;
    color: #415055;
    font-family: 'parka-regular';
    word-break: keep-all;
}
.consultation-message-web:is(h1, h3, h2, b) {
    font-family: 'parka-bold';
    word-break: keep-all;
}

.consultation-message-web h1 {
    padding-top: 4px;
}

.consultation-message-web a {
    text-decoration-line: underline;
    text-decoration-color: #26f8ab;
    color: #677377;
    text-decoration-style: 'solid';
    word-break: keep-all;
    cursor: pointer;
}
.consultation-message-web a:hover {
    color: #26f8ab!important;
    transition: 0.2s;
}

.consultation-message-web ul {
    padding-bottom: 41px;
    list-style-type: disc;
    list-style-position: inside
}

.consultation-message-web ul li {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 4px;
    font-family: 'parka-regular';
    color: #677377;
}

.tenant-consultation-icon {
    display: none;
}

.consultation-message-web .consultation-img-holder {
    height: 230px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}
.consultation-message-web .consultation-img-holder img {
    max-width: 100%;
    max-height: 100%;
}

.wrap-tenant-consultations h3,
.consultation-message-web .wrap-tenant-consultations p,
.consultation-message-web .wrap-tenant-consultations a {
    font-size: 16px;
    line-height: 24px;
    font-family: 'parka-regular';
    color: #415055;
    padding-bottom: 21px;
}

.consultation-message-web .wrap-tenant-consultations h3 {
    font-family: 'parka-bold';
}
